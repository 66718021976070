// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tQ8LCmij6VEOr3oy4PVQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: #fff;
  gap: 20px;
  height: 100%;
}

.FpbzaZfvu4bup5lVZZTy {
  text-align: end;
}
`, "",{"version":3,"sources":["webpack://./src/pages/CustomerDetail/views/History/History.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,gBAAgB;EAChB,SAAS;EACT,YAAY;AACd;;AAEA;EACE,eAAe;AACjB","sourcesContent":[".containerHistory {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n  width: 100%;\n  background: #fff;\n  gap: 20px;\n  height: 100%;\n}\n\n.textTableHistory {\n  text-align: end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerHistory": `tQ8LCmij6VEOr3oy4PVQ`,
	"textTableHistory": `FpbzaZfvu4bup5lVZZTy`
};
export default ___CSS_LOADER_EXPORT___;
