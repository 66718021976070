import { getCustomers } from "@Services/clients/getClients";
import { handleApiError } from "@Services/constServices";

interface TableData {
  columnOne: string;
  columnOneClick: any;
  columnTwo: string;
  columnThree: string;
}
interface AdaptCustomersToTableFormat {
  data: TableData[];
  totalPages: number;
  isSuccess: boolean;
  message?: string;
  status: number;
}

export const adaptCustomersToTableFormat = async (
  currentPagination: number,
  setCriticalError: (value: boolean) => void
): Promise<AdaptCustomersToTableFormat> => {
  try {
    const customersResponse = await getCustomers(currentPagination);
    const res = {
      ...customersResponse,
      data: customersResponse.data[0].customers,
      totalPages: customersResponse.data[0].totalPages,
    };
    return res;
  } catch (error) {
    setCriticalError(true);
    return { ...handleApiError(error), data: [], totalPages: 0 };
  }
};
