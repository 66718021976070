import { useEffect, useState } from "react";
import { adaptCustomerServiceHistory } from "@Adapters/adaptCustomerServiceHistory";
import { useClientsContext } from "@Contexts/clientsContext";

const useGetHistory = (idClient, setIsLoadingHistoryTable, activeButton) => {
  const [dataTable, setDataTable] = useState([]);
  const [dataTableMobile, setDataTableMobile] = useState<any>([]);
  const [showModalDetailsHistory, setShowModalDetailsHistory] = useState(null);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const { setNotification } = useClientsContext();
  const [currentPagination, setCurrentPagination] = useState<number>(1);

  useEffect(() => {
    (async () => {
      if (activeButton === "history") {
        setIsLoadingHistoryTable(true);
        const response = await adaptCustomerServiceHistory(
          idClient,
          currentPagination,
          setShowModalDetailsHistory
        );
        if (response.isSuccess) {
          const orderDataTable = response.data.dataTable;
          const orderDataTableMobile = response.data.dataTableMobile;
          const maxPages = response.data.maxPages;
          setDataTable(orderDataTable);
          setDataTableMobile(orderDataTableMobile);
          setNumberOfPages(maxPages);
        } else {
          setNotification({
            message:
              "Error al obtener la información del historial del cliente",
            status: 400,
          });
        }
        setIsLoadingHistoryTable(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idClient, activeButton, currentPagination]);

  return {
    dataTable,
    showModalDetailsHistory,
    setShowModalDetailsHistory,
    dataTableMobile,
    numberOfPages,
    setCurrentPagination,
    currentPagination,
  };
};

export default useGetHistory;
