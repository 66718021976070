import { useEffect, useState } from "react";
import { adaptCustomerReservations } from "@Adapters/adaptCustomerReservations";

const useGetReservations = (idClient) => {
  const [reservation, setReservation] = useState({
    totalReservations: 0,
    percentageRescheduled: "0%",
    percentageUnattended: "0%",
  });
  const [dataTable, setDataTable] = useState([
    {
      columnOne: "",
      columnTwo: "",
      columnThree: "",
    },
  ]);

  const [stateResponse, setStateResponse] = useState({
    message: "",
    status: 0,
  });

  useEffect(() => {
    const fetchReservations = async () => {
      try {
        const response = await adaptCustomerReservations(idClient);
        if (response.isSuccess) {
          setReservation({ ...reservation, ...response.reservations });
          const orderDataTable = response.dataTable;
          setDataTable(orderDataTable);
        } else {
          setStateResponse({
            message:
              "Error al obtener la información de las reservas del cliente",
            status: response.status,
          });
        }
      } catch (error) {
        setStateResponse({
          message:
            "Error al obtener la información de las reservas del cliente",
          status: 500,
        });
      }
    };

    fetchReservations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idClient]);

  return { reservation, dataTable, stateResponse };
};

export default useGetReservations;
