import { getCustomerProfile } from "@Services/clients/customerProfile";
import {
  handleApiError,
  handleSuccessfulResponse,
} from "@Services/constServices";
import {
  calculateAge,
  formatAndCalculateAge,
} from "@Utilities/formatAndCalculateAge";
import { formatTelephoneNumber } from "@Utilities/formatTelephoneNumber";
import { formatString } from "@Utilities/formatString";
import { formattedDateAndHour } from "@Utilities/formattedDate";
import { formatEmail } from "@Utilities/formatEmail";
import { formatDocument } from "@Utilities/formatDocument";
import { formatPhoneNumber } from "@Utilities/formatPhoneNumber";
import { convertadaptTextToGenders } from "@Utilities/convertadaptTextToGenders";

export interface CustomerProfile {
  fullName: string;
  email: string;
  phoneNumber: string;
  codeCountryPhone: string;
  birthday: string;
  photo: string;
  attentionStatus: boolean;
  category: string;
  gender: string;
  document: string;
  creationDate: string;
  age: number | string;
  address: string;
  documentType: string;
}

export const adaptCustomerProfile = async (idClient: string) => {
  const response = await getCustomerProfile(idClient);
  if (response.isSuccess) {
    const customerProfile: CustomerProfile = {
      fullName: response.data.fullname,
      email: formatEmail(response.data.personalInfo.email),
      phoneNumber: formatTelephoneNumber(response.data.personalInfo.phone),
      codeCountryPhone: response.data.codeCountryPhone || "51",
      birthday: formatAndCalculateAge(response.data.personalInfo.birthday),
      photo: response.data.customerPhoto,
      attentionStatus: response.data.attentionStatus,
      category:
        response.data.clientCategoryName === null
          ? ""
          : response.data.clientCategoryName,
      gender: response.data.personalInfo.gender,
      document: formatDocument(
        response.data.personalInfo.documentType,
        response.data.personalInfo.documentNumber
      ),
      creationDate: formattedDateAndHour(response.data.creationDate),
      age: calculateAge(response.data.personalInfo.birthday),
      address: response.data.address,
      documentType: response.data.personalInfo.documentType,
    };

    const clientEdit = {
      name: formatString(response.data.firstName),
      lastName: formatString(response.data?.lastName),
      email: response.data.personalInfo?.email,
      phoneNumber: formatPhoneNumber(response.data.personalInfo?.phone),
      codeCountryPhone: response.data.codeCountryPhone || "51",
      categoryClientId:
        response.data.clientCategoryId === null
          ? ""
          : response.data.clientCategoryId,
      documentType: response.data.personalInfo.documentType,
      documentNumber: response.data.personalInfo.documentNumber,
      sex: convertadaptTextToGenders(response.data.personalInfo?.gender),
      birthdate: response.data.personalInfo?.birthday,
      address: response.data.address,
    };
    return {
      ...handleSuccessfulResponse(response),
      customerProfile: customerProfile,
      clientEdit: clientEdit,
    };
  } else {
    return {
      ...handleApiError(response),
      customerProfile: {},
      clientEdit: {
        name: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        codeCountryPhone: "",
        categoryClientId: "",
        documentType: "",
        documentNumber: "",
        sex: "",
        birthdate: "",
        address: "",
      },
    };
  }
};
