// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YZ1TzFYDlHzhoCDm6M5V {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.YZ1TzFYDlHzhoCDm6M5V div {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
}

.YZ1TzFYDlHzhoCDm6M5V div article {
  display: flex;
  padding: 20px;
  min-width: 280px;
  justify-content: center;
  align-items: center;
  gap: 16px;
  border-radius: 8px;
  border: 0.5px solid #937cf4;
  background: #fff;
  box-shadow: 8px 3px 22px 10px rgba(150, 150, 150, 0.11);
}

.YZ1TzFYDlHzhoCDm6M5V div article p {
  width: 123px;
  height: 36px;
  color: #4d4d4d;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.2px;
}

.YZ1TzFYDlHzhoCDm6M5V div article h2 {
  color: #4d4d4d;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.2px;
}

@media screen and (max-width: 1080px) {
  .HFhnxh3XbQgHiUE2SZoa {
    flex-direction: column;
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/CustomerDetail/views/Booking/Booking.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,SAAS;AACX;;AAEA;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,SAAS;EACT,kBAAkB;EAClB,2BAA2B;EAC3B,gBAAgB;EAChB,uDAAuD;AACzD;;AAEA;EACE,YAAY;EACZ,YAAY;EACZ,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE,cAAc;EACd,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,qBAAqB;AACvB;;AAEA;EACE;IACE,sBAAsB;IACtB,kBAAkB;EACpB;AACF","sourcesContent":[".containerBooking {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 40px;\n}\n\n.containerBooking div {\n  display: flex;\n  gap: 40px;\n  justify-content: center;\n  align-items: center;\n}\n\n.containerBooking div article {\n  display: flex;\n  padding: 20px;\n  min-width: 280px;\n  justify-content: center;\n  align-items: center;\n  gap: 16px;\n  border-radius: 8px;\n  border: 0.5px solid #937cf4;\n  background: #fff;\n  box-shadow: 8px 3px 22px 10px rgba(150, 150, 150, 0.11);\n}\n\n.containerBooking div article p {\n  width: 123px;\n  height: 36px;\n  color: #4d4d4d;\n  font-size: 14px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n  letter-spacing: 0.2px;\n}\n\n.containerBooking div article h2 {\n  color: #4d4d4d;\n  font-size: 40px;\n  font-style: normal;\n  font-weight: 700;\n  line-height: normal;\n  letter-spacing: 0.2px;\n}\n\n@media screen and (max-width: 1080px) {\n  .cardReservation {\n    flex-direction: column;\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerBooking": `YZ1TzFYDlHzhoCDm6M5V`,
	"cardReservation": `HFhnxh3XbQgHiUE2SZoa`
};
export default ___CSS_LOADER_EXPORT___;
