import style from "./History.module.css";
import {
  TableWrapper,
  PaginationWrapper,
  TableMobile,
} from "../../../../resources";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { CommandIcon } from "@Models/icons/icons";
//@ts-ignore
import { NoInfoToShow } from "@viuti/recursos";
import { adaptDataTableMobile } from "./adaptDataMobile/adaptDataMobile";
import { RowDetails } from "./RowDetails/RowDetails";

const History = ({
  data,
  dataMobile,
  isLoadingHistoryTable,
  numberOfPages,
  currentPagination,
  setCurrentPagination,
  showModalDetailsHistory,
  setShowModalDetailsHistory,
}) => {
  const screenWidth = useScreenWidth();
  const moreThanOneButton = numberOfPages > 1;

  if (data?.length === 0 && !isLoadingHistoryTable) {
    return (
      <section>
        <NoInfoToShow
          title="Historial vacío"
          message="No se hallaron registros para este cliente."
          icon={CommandIcon}
        />
      </section>
    );
  }
  return (
    <section className={style.containerHistory}>
      {screenWidth >= 900 ? (
        <TableWrapper
          titles={["N° de documento", "Fecha", "Hora", "Cajero", "Total", "-"]}
          data={data}
          isLoading={isLoadingHistoryTable}
          cellStyle={{ width: "150px" }}
        />
      ) : (
        <TableMobile
          isLoading={isLoadingHistoryTable}
          dataTable={dataMobile.map((data, index) =>
            adaptDataTableMobile(data, index)
          )}
        />
      )}

      <RowDetails
        showModalDetailsHistory={showModalDetailsHistory}
        setShowModalDetailsHistory={setShowModalDetailsHistory}
      />

      {moreThanOneButton && (
        <PaginationWrapper
          currentPagination={currentPagination}
          setCurrentPagination={setCurrentPagination}
          optionsPerPage={10}
          numberOfButtons={numberOfPages}
        />
      )}
    </section>
  );
};

export default History;
