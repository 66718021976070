import { CustomerSearched } from "@Interfaces/customers";
import { searchCustomers } from "@Services/clients/searchCustomers";
import { handleApiError } from "@Services/constServices";
import { formatDocument } from "@Utilities/formatDocument";
import { formatString } from "@Utilities/formatString";
import { truncateString } from "@Utilities/truncateString";

export interface AdaptedSearchData {
  customerId: string;
  fullName: string;
  formattedDocument: string;
  email: string;
}
// Reveer estas funciones para hacerlas mas entendibles
const adaptedResponseCustomer = async (input: string, setStateResponse) => {
  try {
    const customersResponse = await searchCustomers(input);
    const adaptedCustomers = adaptToSearch(customersResponse.data);
    const adaptedResponse = { ...customersResponse, data: adaptedCustomers };
    return adaptedResponse;
  } catch (error) {
    setStateResponse({
      message:
        "Hubo un error al buscar los clientes. Por favor, intente nuevamente.",
      status: 400,
    });
    return { ...handleApiError(error), data: [] };
  }
};

const adaptToSearch = (customers: CustomerSearched[]): AdaptedSearchData[] => {
  const adaptedCustomers = customers.map((customer) => ({
    customerId: customer.customerId.toString(),
    fullName: `${formatString(customer.firstName)} ${formatString(
      customer.lastName
    )}`,
    formattedDocument: formatDocument(
      customer.documentType,
      customer.customerDocument
    ),
    email: customer.email,
  }));
  const firstFiveResults = adaptedCustomers;
  return firstFiveResults;
};

export const adaptedCustomerSearch = async (
  value,
  navigate,
  setStateResponse
) => {
  if (value.length === 0) {
    return [];
  } else {
    try {
      const adaptedResponse = await adaptedResponseCustomer(
        value,
        setStateResponse
      );
      const adaptedClients: AdaptedSearchData[] = adaptedResponse.data;
      return adaptedClients.map((client) => ({
        idClient: client.customerId,
        image: "",
        fullName: client.fullName,
        info: `${client.formattedDocument} ${truncateString(client.email, 20)}`,
        onClick: () => navigate(`/detalles/${client.customerId}`),
      }));
    } catch (error) {
      return [];
    }
  }
};
