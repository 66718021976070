import { ApiResponse, baseUrl, fetchData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getCustomerServiceHistory = async (
  idClient: string,
  page: number
): Promise<ApiResponse> => {
  const url = `${baseUrl}/GetCustomerHistory?customerId=${idClient}&page=${page}`;
  const ingresa_vista_historial_del_cliente = 10;
  trackUserAction(ingresa_vista_historial_del_cliente);
  return fetchData(url);
};
