import React from "react";
import MainRoutes from "./routes/MainRoutes";
import { BrowserRouter } from "react-router-dom";
import { ClientsProvider } from "./contexts/clientsContext";
// @ts-ignore
import { SlideNotification } from "@viuti/recursos";

import "./App.css";
const App: React.FC = () => {
  const [notification, setNotification] = React.useState({
    message: "",
    status: 0,
    toastTimeDuration: 4000,
  });

  return (
    <BrowserRouter basename="/clientes">
      <ClientsProvider setNotification={setNotification}>
        <MainRoutes />
      </ClientsProvider>
      <SlideNotification
        state={notification}
        clearStatus={() =>
          setNotification({ message: "", status: 0, toastTimeDuration: 4000 })
        }
      />
    </BrowserRouter>
  );
};

export default App;
