export const formatDayMonthYear = (inputDate: string) => {
  if (!inputDate) {
    return "Faltan datos";
  }

  const date = new Date(`${inputDate}T00:00:00`);
  if (isNaN(date.getTime())) {
    return "Fecha inválida";
  }

  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  };
  return date.toLocaleDateString(undefined, options);
};
