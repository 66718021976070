import { customersAttendedTodayAndAverageTickets } from "@Services/clients/clientsBeingServed";
import { handleApiError } from "@Services/constServices";
import formatNumberToPrice from "@Utilities/formatNumberToPrice";

export const customersAttendedTodayAndAverageTicketsAdapter = async (
  setIsLoading,
  setStateResponse
) => {
  try {
    const response2 = await customersAttendedTodayAndAverageTickets();
    setIsLoading((prev) => ({ ...prev, loadingResponse2: false }));
    return {
      clientsServedToday: response2.data.customersAttendedToday,
      ticketAverage: formatNumberToPrice(response2.data.averageTickets),
    };
  } catch (error) {
    setStateResponse({
      message: "Hubo un problema al obtener los clientes siendo atendidos.",
      status: 400,
    });
    return { ...handleApiError(error), tableData: [] };
  }
};
