import { useEffect, useState } from "react";
import style from "./CustomerDetail.module.css";
import { useNavigate, useParams } from "react-router";
import {
  Avatar,
  Icon,
  SlideNotification,
  CriticalErrorPage,
  validateModuleAccess,
} from "../../resources";
import {
  AddCalendarIcon,
  ArrowLeftIcon,
  LoadingPulseIcon,
  WhatsAppIcon,
} from "@Models/icons/icons";
import PersonalInformation from "./views/PersonalInformation/PersonalInformation";
import Booking from "./views/Booking/Booking";
import History from "./views/History/History";
import { adaptCustomerProfile } from "@Adapters/adaptCustomerProfile";
import { DNI } from "@Models/constGlobals";
import useGetHistory from "@Hooks/useGetHistory/useGetHistory";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { capitalizeFirstLetters } from "@Utilities/capitalizeFirstLetters";
import { SHOW_CUSTOMERS_UUID } from "@Models/consts/securityAccess";
import { ROUTE_STORE } from "@Models/routes/routes";
// @ts-ignore
import { FormReservation } from "@viuti/recursos";

const CustomerDetail = () => {
  const { id } = useParams();
  const [activeButton, setActiveButton] = useState("personalInformation");
  const [picture, setPicture] = useState({
    picture: "",
    name: "Anónimo",
  });
  const [criticalError, setCriticalError] = useState<boolean>(false);
  const [customerInformation, setCustomerInformation] = useState({
    fullName: "",
    email: "",
    phoneNumber: "",
    codeCountryPhone: "",
    birthday: "",
    photo: "",
    attentionStatus: false,
    category: "",
    gender: "",
    document: "",
    creationDate: "",
    documentType: "",
  });
  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });
  const screenWidth = useScreenWidth();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [form, setForm] = useState({
    name: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    codeCountryPhone: "51",
    categoryClientId: "",
    documentType: DNI,
    documentNumber: "",
    sex: "",
    birthdate: "",
    address: "",
  });
  const [initialInfo, setInitialInfo] = useState(form);
  const [openModalNewAppointment, setOpenModalNewAppointment] =
    useState<boolean>(false);
  const [iconColor, setIconColor] = useState<string>("#937cf4");

  const adaptClientReserve = {
    name: capitalizeFirstLetters(form.name),
    surname: capitalizeFirstLetters(form.lastName),
    birthDate: form.birthdate || "Sin especificar",
    document: form.documentNumber,
    category: customerInformation.category || "Sin especificar",
    email: form.email || "Sin especificar",
    phone: form.phoneNumber || "Sin especificar",
    id: id,
  };

  useEffect(() => {
    const getInformation = async () => {
      if (!validateModuleAccess(SHOW_CUSTOMERS_UUID, false)) {
        navigate(ROUTE_STORE.ROUTE_MAIN_VIEW);
      }

      const response = await adaptCustomerProfile(id);
      if (response.isSuccess) {
        setCustomerInformation({
          ...customerInformation,
          ...response.customerProfile,
        });
        setInitialInfo(response.clientEdit);
        setForm({
          name: response.clientEdit.name,
          lastName: response.clientEdit.lastName,
          email: response.clientEdit.email,
          phoneNumber: response.clientEdit.phoneNumber,
          codeCountryPhone: response.clientEdit.codeCountryPhone,
          categoryClientId: response.clientEdit.categoryClientId,
          documentType: response.clientEdit.documentType,
          documentNumber: response.clientEdit.documentNumber,
          sex: response.clientEdit.sex,
          birthdate: response.clientEdit.birthdate,
          address: response.clientEdit.address,
        });
        setIsLoading(false);
      } else {
        setCriticalError(true);
        setIsLoading(false);
        setStateResponse({
          message: "Error al obtener la información del cliente",
          status: response.status,
        });
      }
    };
    getInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setPicture({
      picture: customerInformation.photo,
      name: customerInformation.fullName.trim(),
    });
  }, [customerInformation]);

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const handleSendMessage = () => {
    const message = `¡Hola ${customerInformation.fullName}!`;
    window.open(
      `https://wa.me/${customerInformation.codeCountryPhone}${
        customerInformation.phoneNumber
      }?text=${encodeURIComponent(message)}`
    );
  };

  const navigate = useNavigate();
  const handleBack = () => {
    // window.history.back();
    navigate("/");
  };

  const [isLoadingHistoryTable, setIsLoadingHistoryTable] =
    useState<boolean>(true);

  const {
    dataTable,
    dataTableMobile,
    showModalDetailsHistory,
    setShowModalDetailsHistory,
    numberOfPages,
    setCurrentPagination,
    currentPagination,
  } = useGetHistory(id, setIsLoadingHistoryTable, activeButton);

  const textInfoPersonal = screenWidth > 510 ? "Información Personal" : "Info.";
  const textHistory = "Historial";

  const renderContent = () => {
    switch (activeButton) {
      case "personalInformation":
        return (
          <PersonalInformation
            personalInformation={customerInformation}
            idClient={id}
            initialInfo={initialInfo}
            form={form}
            setForm={setForm}
            setCustomerInformation={setCustomerInformation}
            setInitialInfo={setInitialInfo}
          />
        );
      case "attentionInProcess":
        return <section>{/* Contenido de Atención en Curso */}</section>;
      case "history":
        return (
          <History
            data={dataTable}
            dataMobile={dataTableMobile}
            isLoadingHistoryTable={isLoadingHistoryTable}
            numberOfPages={numberOfPages}
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
            showModalDetailsHistory={showModalDetailsHistory}
            setShowModalDetailsHistory={setShowModalDetailsHistory}
          />
        );
      case "booking":
        return <Booking idClient={id} />;
      default:
        return null;
    }
  };

  if (isLoading)
    return (
      <div id="viuti-front-mainContent">
        <figure className={style.loadingIcon}>
          <img src={LoadingPulseIcon} alt="Cargando..." />
        </figure>
      </div>
    );
  const titleTextPage = screenWidth < 320 ? "Perfil" : "Perfil del cliente";
  if (criticalError) {
    return (
      <div id="viuti-front-mainContent">
        <div className={style.backContainer}>
          <button className={style.containerButtonBack} onClick={handleBack}>
            <Icon path={ArrowLeftIcon} size={18} color={"#fff"} />
          </button>
          <h2>{titleTextPage}</h2>
        </div>
        <CriticalErrorPage />
        <SlideNotification
          state={stateResponse}
          clearStatus={() => setStateResponse({ message: "", status: 0 })}
        />
      </div>
    );
  }
  return (
    <div id="viuti-front-mainContent">
      <div className={style.backContainer}>
        <button className={style.containerButtonBack} onClick={handleBack}>
          <Icon path={ArrowLeftIcon} size={18} color={"#fff"} />
        </button>
        <h2>{titleTextPage}</h2>
      </div>
      <div className={style.containerCustomerDetail}>
        <section className={style.section}>
          <header className={style.headerCustomerDetail}>
            <div>
              <Avatar
                picture={picture.picture}
                name={picture.name}
                setPicture={setPicture}
                editImagen={false}
              />
              <div className={style.containerNameAndDate}>
                <h2>{capitalizeFirstLetters(customerInformation.fullName)}</h2>
                {screenWidth <= 800 && (
                  <div className={style.containerButtonsActionsMobile}>
                    <button
                      className={style.buttonAddCalendarMobile}
                      onClick={() => setOpenModalNewAppointment(true)}
                    >
                      <Icon
                        path={AddCalendarIcon}
                        color={"#937cf4"}
                        size={22}
                      />
                    </button>
                    <button
                      className={
                        initialInfo.phoneNumber === ""
                          ? style.disabledButtonWsp
                          : style.buttonMobileWsp
                      }
                      onClick={() => {
                        if (initialInfo.phoneNumber !== "") handleSendMessage();
                      }}
                    >
                      <img src={WhatsAppIcon} alt="WhatsApp" />
                    </button>
                  </div>
                )}
                <p>Cliente desde: {customerInformation.creationDate}</p>
              </div>
              {/* <p className={style.status}>
                <span className={style.icon}>
                  <Icon path={GreenPointIcon} size={13} color={"#00B71D"} />
                </span>
                <span>Acualmente en el salón</span>
              </p> */}
            </div>
            <div className={style.containerButtonsActions}>
              {screenWidth > 800 && (
                <button
                  className={style.buttonAddCalendar}
                  onClick={() => setOpenModalNewAppointment(true)}
                  onMouseEnter={() => setIconColor("#fff")}
                  onMouseLeave={() => setIconColor("#937cf4")}
                >
                  <Icon path={AddCalendarIcon} color={iconColor} size={22} />
                  <span className={style.msjW}>Agregar reserva</span>
                </button>
              )}
              {/*<button>
                <Icon path={AddCommandIcon} color={"#fff"} size={22} />
              </button> */}
              {screenWidth > 800 && (
                <button
                  onClick={() => {
                    if (initialInfo.phoneNumber !== "") handleSendMessage();
                  }}
                  className={
                    initialInfo.phoneNumber === ""
                      ? style.disabledButtonWsp
                      : style.buttonWsp
                  }
                >
                  <img src={WhatsAppIcon} alt="WhatsApp" />
                  <span className={style.msjW}>Enviar mensaje</span>
                </button>
              )}
            </div>
          </header>
          <footer className={style.footerCustomerDetail}>
            <button
              style={{
                border: screenWidth < 510 ? "1px solid rgb(204, 204, 204)" : "",
              }}
              className={
                activeButton === "personalInformation"
                  ? style.activeButton
                  : style.disabledButton
              }
              onClick={() => handleButtonClick("personalInformation")}
            >
              <p>{textInfoPersonal}</p>
            </button>
            {/* <button
              className={
                activeButton === "attentionInProcess"
                  ? style.activeButton
                  : style.disabledButton
              }
              onClick={() => handleButtonClick("attentionInProcess")}
            >
              Atención en curso
            </button> */}
            <button
              style={{
                border: screenWidth < 510 ? "1px solid rgb(204, 204, 204)" : "",
              }}
              className={
                activeButton === "history"
                  ? style.activeButton
                  : style.disabledButton
              }
              onClick={() => handleButtonClick("history")}
            >
              <p>{textHistory}</p>
            </button>
            {/* <button
              className={
                activeButton === "booking"
                  ? style.activeButton
                  : style.disabledButton
              }
              onClick={() => handleButtonClick("booking")}
            >
              Reservas
            </button> */}
          </footer>
        </section>
        {renderContent()}
      </div>
      {openModalNewAppointment && (
        <FormReservation
          setToasterResponse={setStateResponse}
          actionButtonClose={() => setOpenModalNewAppointment(false)}
          initialCustomer={adaptClientReserve}
          callBacks={{
            onClose: () => setOpenModalNewAppointment(false),
            onSuccess: () => {
              setOpenModalNewAppointment(false);
              setStateResponse({
                message: "Reserva creada correctamente",
                status: 200,
              });
            },
            onError: () => {
              setStateResponse({
                message: "Error al crear la reserva, intente nuevamente",
                status: 400,
              });
            },
            onDeleteSuccess: () => {
              setStateResponse({
                message: "Reserva cancelada correctamente",
                status: 200,
              });
            },
          }}
        />
      )}
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </div>
  );
};

export default CustomerDetail;
