export const formattedDateAndHour = (inputDate: string): string => {
  if (!inputDate) {
    return " - ";
  }
  // me va a llegar esta fecha: 2024-05-22 y quiero que me devuelva esto por ejemplo: 21 may. 2024
  const day = inputDate.slice(8, 10);
  const month = inputDate.slice(5, 7);
  const year = inputDate.slice(0, 4);
  const months = [
    "ene.",
    "feb.",
    "mar.",
    "abr.",
    "may.",
    "jun.",
    "jul.",
    "ago.",
    "sep.",
    "oct.",
    "nov.",
    "dic.",
  ];
  const monthName = months[parseInt(month) - 1];
  return `${day} ${monthName} ${year}`;
};

export const formatHourDateToCustomFormat = (dateString: string): string => {
  // Extraer los componentes de la cadena de fecha
  const [datePart, timePart] = dateString.split("T");
  const [year, month, day] = datePart.split("-");
  const [hours, minutes, seconds] = timePart.split(":");

  // Convertir a formato de 12 horas
  let hour = parseInt(hours, 10);
  const ampm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12;
  hour = hour ? hour : 12; // El '0' debe ser '12'

  // Formatear los componentes
  const formattedDay = day.padStart(2, "0");
  const formattedMonth = month.padStart(2, "0");
  const formattedYear = year;

  const formattedHours = String(hour).padStart(2, "0");
  const formattedMinutes = minutes.padStart(2, "0");

  return `${formattedHours}:${formattedMinutes} ${ampm}`;
};

export const formatToCustomFormat = (dateString: string): string => {
  const [datePart] = dateString.split("T");
  const [year, month, day] = datePart.split("-");
  return `${day}-${month}-${year}`;
};
