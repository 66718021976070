import { useEffect, useState } from "react";
import { TableWrapper, PaginationWrapper, Tooltip } from "../../../resources";
import style from "./CustomerTable.module.css";
import { capitalizeFirstLetters } from "@Utilities/capitalizeFirstLetters";
import { formatDocumentTable } from "@Utilities/formatDocument";
import { formattedDateAndHour } from "@Utilities/formattedDate";
import { useNavigate } from "react-router";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { createObjectClientsMobile } from "./CustomerTableFunctions/customerTableFunctions";
import { CommonArrowRightIcon } from "@Models/icons/icons";
import { MobileList } from "./MobileList/MobileList";
import { truncateString } from "@Utilities/truncateString";

const CustomerTable = ({
  customersList,
  currentPagination,
  setCurrentPagination,
  maxPages,
  isLoadingCustomersTable,
  criticalError,
}) => {
  const [pages, setPages] = useState(1);
  const navigate = useNavigate();
  const screenWidth = useScreenWidth();
  const titles = ["Cliente", "Documento", "Última atención"];

  const adaptToTable = (customers) =>
    customers.map((customer) => ({
      name: (
        <Tooltip
          message={capitalizeFirstLetters(customer.names)}
          activated={customer.names.length > 30}
        >
          <button
            className={style.name}
            onClick={() => navigate(`/detalles/${customer.customerId}`)}
          >
            {truncateString(capitalizeFirstLetters(customer.names), 30)}
          </button>
        </Tooltip>
      ),
      document: formatDocumentTable(
        customer.documentType,
        customer.documentNumber
      ),
      lastAttention: formattedDateAndHour(customer.lastAttention),
    }));

  useEffect(() => {
    setPages(maxPages);
  }, [customersList, maxPages]);

  return (
    <>
      {/* {criticalError ? <CriticalErrorPage /> : */}
      <>
        {screenWidth > 900 ? (
          <TableWrapper
            titles={titles}
            isLoading={isLoadingCustomersTable}
            data={adaptToTable(customersList)}
            cellStyle={{
              width:
                screenWidth > 1000
                  ? "250px"
                  : screenWidth > 600
                  ? "200px"
                  : "150px",
            }}
            headerStyle={{
              backgroundColor: "#937CF4",
              fontSize: "14px",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "0.14px",
              borderRight: "none",
              borderLeft: "none",
              whiteSpace: "nowrap",
              background: "#f0edfe",
              color: "#45348e",
              padding: "8px 22px ",
              borderBottom: "none",
              fontWeight: 700,
              textAlign: "center",
            }}
          />
        ) : (
          <MobileList
            isLoading={isLoadingCustomersTable}
            icon={CommonArrowRightIcon}
            action={(id) => {
              if (String(id).slice(0, 7) === "default") {
                return;
              } else {
                navigate(`/detalles/${id}`);
              }
            }}
            dataTable={customersList.map((customer) => ({
              ...createObjectClientsMobile({
                customer,
                screenWidth,
              }),
            }))}
          />
        )}

        {pages > 1 && (
          <PaginationWrapper
            currentPagination={currentPagination}
            setCurrentPagination={setCurrentPagination}
            optionsPerPage={10}
            numberOfButtons={pages}
          />
        )}
      </>
      {/* } */}
    </>
  );
};

export default CustomerTable;
