import { formatToSoles } from "@Utilities/FormatsHandler";
import {
  formatHourDateToCustomFormat,
  formattedDateAndHour,
  formatToCustomFormat,
} from "@Utilities/formattedDate";

export const adaptDataTableMobile = (data: any, index) => {
  const headerTitles = [
    data.documentNumber,
    formattedDateAndHour(data.dateTime),
  ];

  return {
    id: index,
    header: headerTitles,
    body: [
      {
        item1: "N° de documento",
        item2: data.documentNumber,
      },
      {
        item1: "Fecha",
        item2: formatToCustomFormat(data.dateTime),
      },
      {
        item1: "Hora",
        item2: formatHourDateToCustomFormat(data.dateTime),
      },
      {
        item1: "Cajero",
        item2: data.employeeName,
      },
      {
        item1: "Valor",
        item2: formatToSoles(data.value),
      },
      {
        item1: "Impuesto",
        item2: formatToSoles(data.tax),
      },
      {
        item1: "ICBPER",
        item2: formatToSoles(data.icbper),
      },
      {
        item1: "Descuento",
        item2: formatToSoles(data.discount),
      },
      {
        item1: "Total",
        item2: formatToSoles(data.total),
      },
    ],
  };
};
