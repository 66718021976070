import { ApiResponse, baseUrl, fetchData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const getCustomerProfile = async (
  idClient: string
): Promise<ApiResponse> => {
  const url = `${baseUrl}/CustomerProfile?IdCliente=${idClient}`;
  const ingresa_vista_perfil_cliente = 9;
  trackUserAction(ingresa_vista_perfil_cliente);
  return fetchData(url);
};
