import { ApiResponse, baseUrl, fetchData } from "@Services/constServices";
// @ts-ignore
import { trackUserAction } from "@viuti/recursos";

export const clientsBeingServed = async (): Promise<ApiResponse> => {
  const url = `${baseUrl}/ClientsBeingServedResponse`;
  return fetchData(url);
};

export const customersAttendedTodayAndAverageTickets =
  async (): Promise<ApiResponse> => {
    const url = `${baseUrl}/CustomersAttendedTodayAndAverageTicketsResponse`;
    const ingresa_vista_clientes = 8;
    trackUserAction(ingresa_vista_clientes);
    return fetchData(url);
  };
