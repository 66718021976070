export const phoneNumberInCustomerProfile = (
  phoneNumber?: string | undefined
): string => {
  if (!phoneNumber || phoneNumber === "undefined" || phoneNumber.length < 9) {
    return "";
  }

  // Eliminar cualquier carácter que no sea un dígito
  const digitsOnly = phoneNumber.replace(/\D/g, "");

  // Formatear el número al formato "XXX XXX XXX"
  const formattedPhoneNumber = digitsOnly.replace(
    /(\d{3})(\d{3})(\d{3})/,
    "$1 $2 $3"
  );

  return formattedPhoneNumber;
};
