import { TripleDotsIcon } from "@Models/icons/icons";
import { getCustomerServiceHistory } from "@Services/clients/getCustomerServiceHistory";
import { formatToSoles } from "@Utilities/FormatsHandler";
import {
  formatHourDateToCustomFormat,
  formatToCustomFormat,
} from "@Utilities/formattedDate";
// @ts-ignore
import { IconWithOptions, Icon } from "@viuti/recursos";

export const adaptCustomerServiceHistory = async (
  idClient: string,
  page: number,
  setShowModalService: any
) => {
  const response = await getCustomerServiceHistory(idClient, page);

  if (response.isSuccess) {
    return {
      ...response,
      data: {
        dataTable: adaptDataTable(
          response.data.result.customerPurchaseHistory,
          setShowModalService
        ),
        dataTableMobile: response.data.result.customerPurchaseHistory,
        maxPages: response.data.result.maxPage,
      },
    };
  } else {
    return {
      ...response,
      data: {
        dataTable: [],
        dataTableMobile: [],
        maxPages: 0,
      },
    };
  }
};
// Se renderiza el ícono de opciones
const renderIconWithOptions = (history, setShowModalService) => (
  <IconWithOptions
    icon={<Icon path={TripleDotsIcon} size={14} alt={"Tres puntos"} />}
    actions={[
      {
        label: "Ver más detalles",
        handler: () => setShowModalService(history),
      },
    ]}
  />
);

const adaptDataTable = (data, setShowModalService) => {
  const dataTable = data.map((history) => {
    return {
      columnOne: <>{history.documentNumber}</>,
      columnTwo: <>{formatToCustomFormat(history.dateTime)}</>,
      columnThree: <>{formatHourDateToCustomFormat(history.dateTime)}</>,
      columnFive: <>{history.employeeName}</>,
      columnFour: <>{formatToSoles(history.total)}</>,
      guion: renderIconWithOptions(history, setShowModalService),
    };
  });
  return dataTable;
};
