import { useState } from "react";
import styles from "../MainView.module.css";
import {
  DataList,
  ModalLayoutWrapper,
  FormCreateNewClient,
  validateModuleAccess,
} from "../../../resources";
import { NavigateFunction, useNavigate } from "react-router";
import useDebouncedSearch from "@Hooks/useDebounceSearch/useDebounceSearch";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { AddIcon, TimesIcon } from "@Models/icons/icons";
import TertiaryButton from "@Components/TertiaryButton/TertiaryButton";
import { useClientsContext } from "@Contexts/clientsContext";
import {
  CREATE_CUSTOMER_UUID,
  SHOW_CUSTOMERS_UUID,
} from "@Models/consts/securityAccess";

const HeaderWithSearchAndForm = ({ setStateResponse, setLoader }) => {
  const [showForm, setShowForm] = useState(false);
  const navigate: NavigateFunction = useNavigate();
  const debounceDelay = 300;
  const screenWidth = useScreenWidth();
  const { setNotification } = useClientsContext();
  const { searchValue, setSearchValue, listOfClientsFound, isLoadingSearch } =
    useDebouncedSearch("", debounceDelay, setStateResponse);

  const handleClick = () => {
    validateModuleAccess(CREATE_CUSTOMER_UUID) && setShowForm(true);
  };

  const createNewClientResponse = (client: any) => {
    setLoader(true);
    setNotification({
      message: "Cliente creado exitosamente",
      status: 200,
    });
    navigate(`/detalles/${client.userId}`);
    setShowForm(false);
  };

  return (
    <>
      {screenWidth <= 900 && (
        <div className={styles.headerWithSearchAndFormContainer}>
          <h2 className={styles.title}>Clientes</h2>
          <TertiaryButton
            buttonProps={{
              onClick: handleClick,
              disabled: false,
            }}
            icon={AddIcon}
            value="Nuevo cliente"
          />
        </div>
      )}
      {screenWidth > 900 && (
        <TertiaryButton
          buttonProps={{
            onClick: handleClick,
            disabled: false,
          }}
          icon={AddIcon}
          value="Nuevo cliente"
        />
      )}

      {validateModuleAccess(SHOW_CUSTOMERS_UUID, false) && (
        <DataList
          placeholder="Buscar cliente por nombre o documento"
          data={listOfClientsFound}
          value={searchValue}
          handleChange={(value) => setSearchValue(value)}
          width={screenWidth > 900 ? "310px" : "100%"}
          category={"clients"}
          isLoading={isLoadingSearch}
        />
      )}
      {showForm && (
        <ModalLayoutWrapper>
          <FormCreateNewClient
            isDisabled={showForm}
            setIsDisabled={setShowForm}
            nextClick={(client) => createNewClientResponse(client)}
          />
        </ModalLayoutWrapper>
      )}
    </>
  );
};

export default HeaderWithSearchAndForm;
