// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UjyoHH9V8QyEdqfEV_Rz {
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  max-width: 180px;
  white-space: nowrap;
}

.UjyoHH9V8QyEdqfEV_Rz:hover {
  text-decoration: underline;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/CustomerTable/CustomerTable.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,uBAAuB;EACvB,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":[".name {\n  cursor: pointer;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: start;\n  max-width: 180px;\n  white-space: nowrap;\n}\n\n.name:hover {\n  text-decoration: underline;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"name": `UjyoHH9V8QyEdqfEV_Rz`
};
export default ___CSS_LOADER_EXPORT___;
