import {
  BirthdayCakeIcon,
  LetterIcon,
  PencilWithFrameIcon,
  PersonalDocumentIcon,
  PhoneIcon,
  SiteIcon,
} from "@Models/icons/icons";
import style from "./PersonalInformation.module.css";
import { useEffect, useRef, useState } from "react";
import {
  ModalLayoutWrapper,
  Form,
  SlideNotification,
  validateModuleAccess,
} from "../../../../resources";
import { putEditClient } from "@Services/clients/putEditClient";
import { RUC } from "@Models/constGlobals";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { phoneNumberInCustomerProfile } from "@Utilities/phoneNumberInCustomerProfile";
import useScreenWidth from "@Hooks/useScreenWidth/useScreenWidth";
import { calcularEdad, formatearFecha } from "@Utilities/formatAndCalculateAge";
import { HiOutlineSquare2Stack, HiMiniTag } from "react-icons/hi2";
import { useClientsCategories } from "./hook/useClientsCategories";
import {
  CREATE_CUSTOMER_UUID,
  MODIFY_CUSTOMERS_UUID,
} from "@Models/consts/securityAccess";

const PersonalInformation = ({
  personalInformation,
  idClient,
  initialInfo,
  form,
  setForm,
  setCustomerInformation,
  setInitialInfo,
}) => {
  const [stateResponse, setStateResponse] = useState<any>({
    message: "",
    status: 0,
  });

  const emailRef = useRef(null);
  const handleCopyToClipboard = (text) => {
    if (text === "-") {
      return;
    }
    const textField = document.createElement("textarea");
    textField.innerText = text;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    // Puedes mostrar una notificación o realizar cualquier acción adicional después de copiar
    setStateResponse({
      message: "Copiado al portapapeles",
      status: 200,
    });
  };
  const [forceDisabled, setForceDisabled] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { categories } = useClientsCategories(setStateResponse);
  const [status, setStatus] = useState({
    error: false,
    validated: true,
    loading: false,
    message: "",
  });

  useEffect(() => {
    if (!showForm) return;
    setForm(initialInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showForm]);

  useEffect(() => {
    setStatus({
      error: false,
      validated: false,
      loading: false,
      message: "",
    });
  }, [form.documentNumber, form.documentType]);

  const postEditClient = async () => {
    setIsLoading(true);
    const res = await putEditClient(form, idClient, initialInfo);
    if (res.isSuccess) {
      if (initialInfo.email !== form.email) {
        setCustomerInformation((prev) => ({
          ...prev,
          email: form.email,
        }));
        setInitialInfo((prev) => ({
          ...prev,
          email: form.email,
        }));
      }
      if (
        initialInfo.phoneNumber !== form.phoneNumber ||
        initialInfo.codeCountryPhone !== form.codeCountryPhone
      ) {
        setCustomerInformation((prev) => ({
          ...prev,
          phoneNumber: form.phoneNumber,
          codeCountryPhone: form.codeCountryPhone,
        }));
        setInitialInfo((prev) => ({
          ...prev,
          phoneNumber: form.phoneNumber,
          codeCountryPhone: form.codeCountryPhone,
        }));
      }
      if (
        initialInfo.name !== form.name ||
        initialInfo.lastName !== form.lastName
      ) {
        setCustomerInformation((prev) => ({
          ...prev,
          fullName: form.name + " " + form.lastName,
        }));
        setInitialInfo((prev) => ({
          ...prev,
          name: form.name,
          lastName: form.lastName,
        }));
      }

      if (initialInfo.birthdate !== form.birthdate) {
        setCustomerInformation((prev) => ({
          ...prev,
          birthday: formatearFecha(form.birthdate),
          age: calcularEdad(form.birthdate),
        }));
        setInitialInfo((prev) => ({
          ...prev,
          birthdate: form.birthdate,
        }));
      }
      if (initialInfo.address !== form.address) {
        setCustomerInformation((prev) => ({
          ...prev,
          address: form.address,
        }));
        setInitialInfo((prev) => ({
          ...prev,
          address: form.address,
        }));
      }
      if (initialInfo.sex !== form.sex) {
        setCustomerInformation((prev) => ({
          ...prev,
          gender: form.sex,
        }));
        setInitialInfo((prev) => ({
          ...prev,
          sex: form.sex,
        }));
      }
      if (initialInfo.categoryClientId !== form.categoryClientId) {
        setCustomerInformation((prev) => ({
          ...prev,
          category: categories.find(
            (category) => category.value === form.categoryClientId
          )?.name,
        }));
        setInitialInfo((prev) => ({
          ...prev,
          categoryClientId: form.categoryClientId,
        }));
      }

      setStateResponse({
        message: `Cliente ${form.name} editado de manera exitosa.`,
        status: 200,
      });
      setIsLoading(false);
      setShowForm(false);
    } else {
      setIsLoading(false);
      setStateResponse({
        message: res.error,
        status: res.status,
      });
    }
  };
  const handleClick = () => {
    validateModuleAccess(MODIFY_CUSTOMERS_UUID) && setShowForm(true);
  };
  const gender = (stringGender) => {
    // return screenWidth > 600 ? stringGender : stringGender.slice(0, 1).toUpperCase();
    return stringGender;
  };

  useEffect(() => {
    if (initialInfo) {
      const {
        name: initialName,
        lastName: initialLastName,
        email: initialEmail,
        codeCountryPhone: initialCodeCountryPhone,
        categoryClientId: initialCategoryClientId,
        phoneNumber: initialPhoneNumber,
        documentType: initialDocumentType,
        documentNumber: initialDocumentNumber,
        sex: initialSex,
        birthdate: initialBirthdate,
        address: initialAddress,
      } = initialInfo;

      const {
        name,
        lastName,
        email,
        phoneNumber,
        codeCountryPhone,
        categoryClientId,
        documentType,
        documentNumber,
        sex,
        birthdate,
        address,
      } = form;

      if (
        name !== initialName ||
        lastName !== initialLastName ||
        email !== initialEmail ||
        phoneNumber !== initialPhoneNumber ||
        codeCountryPhone !== initialCodeCountryPhone ||
        documentType !== initialDocumentType ||
        documentNumber !== initialDocumentNumber ||
        sex !== initialSex ||
        birthdate !== initialBirthdate ||
        address !== initialAddress ||
        categoryClientId !== initialCategoryClientId
      ) {
        setForceDisabled(false);
      } else {
        setForceDisabled(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const screenWidth = useScreenWidth();
  const lengthEmail = screenWidth < 400 ? 18 : 25;
  return (
    <section className={style.personalInformationContainer}>
      <div className={style.info}>
        <figure>
          <img src={PersonalDocumentIcon} alt="Documento" />
        </figure>
        <p>
          {personalInformation.documentType === RUC
            ? personalInformation.document
            : personalInformation.document +
              " - " +
              gender(personalInformation.gender)}
        </p>
      </div>
      <div className={style.container_category}>
        <figure>
          <HiMiniTag size={28} color={"#937cf4"} />
        </figure>
        <p>{personalInformation.category || "-"}</p>
      </div>
      {personalInformation.documentType === RUC ? (
        <div className={style.info}>
          <figure>
            <img src={SiteIcon} alt="Dirección" />
          </figure>
          <p>{personalInformation.address}</p>
        </div>
      ) : (
        <div className={style.info}>
          <figure className={style.BirthdayCakeIconBackground}>
            <img src={BirthdayCakeIcon} alt="Torta de cumpleaños" />
          </figure>
          <div className={style.textBirthday}>
            <p>
              <strong>{personalInformation.birthday}</strong>
            </p>
            <p>({personalInformation.age})</p>
          </div>
        </div>
      )}
      <div className={style.info}>
        <figure>
          <img src={PhoneIcon} alt="Telefono" />
        </figure>
        <p
          ref={emailRef}
          onClick={() =>
            handleCopyToClipboard(
              phoneNumberInCustomerProfile(personalInformation.phoneNumber)
            )
          }
          className={`${style.emailStyle} ${
            personalInformation.phoneNumber !== "" ? style.styleScale : ""
          }`}
          style={{
            textDecoration:
              personalInformation.phoneNumber === "" ? "none" : "underline",
            cursor:
              personalInformation.phoneNumber === "" ? "default" : "pointer",
          }}
        >
          {personalInformation.phoneNumber !== "" &&
            "+" + personalInformation.codeCountryPhone + " "}
          {phoneNumberInCustomerProfile(personalInformation.phoneNumber)}
          {phoneNumberInCustomerProfile(personalInformation.phoneNumber) ===
            "" && "-"}
          {personalInformation?.phoneNumber !== "" && <HiOutlineSquare2Stack />}
        </p>
      </div>

      <div className={style.info}>
        <figure>
          <img src={LetterIcon} alt="Carta" />
        </figure>
        <p
          data-tooltip-id="my-tooltip"
          data-tooltip-content={personalInformation.email}
          ref={emailRef}
          onClick={() => handleCopyToClipboard(personalInformation.email)}
          className={`${style.emailStyle} ${
            personalInformation.email !== "-" ? style.styleScale : ""
          }`}
          style={{
            textDecoration:
              personalInformation.email === "-" ? "none" : "underline",
            cursor: personalInformation.email === "-" ? "default" : "pointer",
          }}
        >
          {personalInformation.email.length > lengthEmail
            ? personalInformation.email.slice(0, lengthEmail) + "..."
            : personalInformation.email}
        </p>
      </div>
      {personalInformation.email.length > lengthEmail && (
        <Tooltip id="my-tooltip" style={{ background: "#937cf4" }} />
      )}
      {personalInformation.document && (
        <div className={style.info}>
          <button className={style.editInfoStyle} onClick={handleClick}>
            <figure>
              <img src={PencilWithFrameIcon} alt="Lápiz" />
            </figure>
            <p>Editar</p>
          </button>
        </div>
      )}
      {showForm && (
        <ModalLayoutWrapper>
          <Form
            backClick={() => setShowForm(false)}
            nextClick={postEditClient}
            form={form}
            setForm={setForm}
            status={status}
            title="Editar cliente"
            textButton="Guardar"
            edit={true}
            isLoading={isLoading}
            forceDisabled={forceDisabled}
            categories={categories}
          />
        </ModalLayoutWrapper>
      )}
      <SlideNotification
        state={stateResponse}
        clearStatus={() => setStateResponse({ message: "", status: 0 })}
      />
    </section>
  );
};

export default PersonalInformation;
