export const formatAndCalculateAge = (birthDate: string): string => {
  if (!birthDate) {
    return "falta completar";
  }

  const [year, month, day] = birthDate.split("-");
  const monthNames = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const monthName = monthNames[parseInt(month, 10) - 1];
  return `${monthName} ${day} del ${year}`;
};

export const calculateAge = (birthDate: string): string => {
  if (!birthDate) {
    return "falta completar";
  }

  const today = new Date();
  const birthDay = new Date(birthDate);

  let age = today.getFullYear() - birthDay.getFullYear();

  if (
    today.getMonth() < birthDay.getMonth() ||
    (today.getMonth() === birthDay.getMonth() &&
      today.getDate() < birthDay.getDate())
  ) {
    age--;
  }

  return age.toString() + " años";
};

export const formatearFecha = (fechaStr: string): string => {
  const fecha: Date = new Date(fechaStr + "T00:00:00");

  const meses: string[] = [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ];
  const nombreMes: string = meses[fecha.getMonth()];
  const dia: number = fecha.getDate();

  // Obtener el año
  const año: number = fecha.getFullYear();

  return `${nombreMes} ${dia} del ${año}`;
};

export const calcularEdad = (fechaNacimientoStr: string): number => {
  const fechaNacimiento: Date = new Date(fechaNacimientoStr + "T00:00:00");

  const fechaActual: Date = new Date();

  const fechaNacimientoMs: number = fechaNacimiento.getTime();

  const diferenciaTiempo: number = fechaActual.getTime() - fechaNacimientoMs;
  const edad: number = Math.floor(
    diferenciaTiempo / (1000 * 60 * 60 * 24 * 365)
  );
  return edad;
};
