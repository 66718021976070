// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QDopjkzRi0AFnaxKwqFk {
  width: 100%;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
}

.MlpxS1vgWgDkYV9l3RI8 {
  display: flex;
  flex-direction: column;
}

.QaLQDMtFQ1UR6apX7thL {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-weight: bold;
  width: 100%;
  padding: 10px;
  border-top: 1px solid rgb(204, 204, 204);
}

.epsyeZ_hi8_9dYGlJd6Y {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  font-weight: 400;
  border: none;
  color: rgb(51, 51, 51);
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  width: 100%;
  white-space: nowrap;
  margin-right: 20px;

  & span {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: start;
    width: 100%;
    white-space: nowrap;
  }
}

.phu6hQG_I8Xlzgdrjjl3 {
  display: flex;
  align-items: center;
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;
  border-radius: 50%;
  background-color: rgb(147, 124, 244);
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/pages/MainView/CustomerTable/MobileList/MobileList.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;EAC9B,WAAW;EACX,iBAAiB;EACjB,WAAW;EACX,aAAa;EACb,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;EACT,gBAAgB;EAChB,YAAY;EACZ,sBAAsB;EACtB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,iBAAiB;EACjB,WAAW;EACX,mBAAmB;EACnB,kBAAkB;;EAElB;IACE,gBAAgB;IAChB,uBAAuB;IACvB,iBAAiB;IACjB,WAAW;IACX,mBAAmB;EACrB;AACF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,oCAAoC;EACpC,uBAAuB;AACzB","sourcesContent":[".container_list {\n  width: 100%;\n  border-radius: 8px;\n  display: flex;\n  flex-direction: column;\n}\n\n.container_row {\n  display: flex;\n  flex-direction: column;\n}\n\n.row {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  color: #fff;\n  font-weight: bold;\n  width: 100%;\n  padding: 10px;\n  border-top: 1px solid rgb(204, 204, 204);\n}\n\n.titles {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n  font-weight: 400;\n  border: none;\n  color: rgb(51, 51, 51);\n  align-items: center;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: start;\n  width: 100%;\n  white-space: nowrap;\n  margin-right: 20px;\n\n  & span {\n    overflow: hidden;\n    text-overflow: ellipsis;\n    text-align: start;\n    width: 100%;\n    white-space: nowrap;\n  }\n}\n\n.iconTable {\n  display: flex;\n  align-items: center;\n  width: 30px;\n  height: 30px;\n  min-width: 30px;\n  min-height: 30px;\n  border-radius: 50%;\n  background-color: rgb(147, 124, 244);\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container_list": `QDopjkzRi0AFnaxKwqFk`,
	"container_row": `MlpxS1vgWgDkYV9l3RI8`,
	"row": `QaLQDMtFQ1UR6apX7thL`,
	"titles": `epsyeZ_hi8_9dYGlJd6Y`,
	"iconTable": `phu6hQG_I8Xlzgdrjjl3`
};
export default ___CSS_LOADER_EXPORT___;
