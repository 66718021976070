import { ApiResponse, baseUrl, putData } from "@Services/constServices";
import { typeDoc } from "@Utilities/typeDoc";

export const putEditClient = async (
  form,
  idClient,
  initialForm
): Promise<ApiResponse> => {
  let codePhone = form.codeCountryPhone;
  let phone = form.phoneNumber;
  if (form.phoneNumber === "") {
    codePhone = "";
    phone = "";
  }

  const sex = (s: string) => {
    if (s === "MASCULINO") return 1;
    if (s === "FEMENINO") return 2;
    return 3;
  };

  // create the potentialData object for form
  const potentialDataForm = {
    firstName: form.name,
    lastName: form.lastName,
    email: form.email,
    phone: phone,
    codeCountryPhone: String(codePhone),
    categoryClientId: form.categoryClientId === "" ? 0 : form.categoryClientId,
    documentType: typeDoc(form.documentType),
    documentNumber: `${form.documentNumber}`,
    gender: sex(form.sex),
    birthdate: form.birthdate,
    address: form.address,
  };

  // create the potentialData object for initialForm
  const potentialDataInitialForm = {
    firstName: initialForm.name,
    lastName: initialForm.lastName,
    email: initialForm.email,
    phone: initialForm.phoneNumber,
    codeCountryPhone: String(initialForm.codeCountryPhone),
    categoryClientId: initialForm.categoryClientId,
    documentType: typeDoc(initialForm.documentType),
    documentNumber: `${initialForm.documentNumber}`,
    gender: sex(initialForm.sex),
    birthdate: initialForm.birthdate,
    address: initialForm.address,
  };

  // create an object data only with the changes
  const data = {};
  for (const key in potentialDataForm) {
    if (potentialDataForm[key] !== potentialDataInitialForm[key]) {
      data[key] = potentialDataForm[key];
    }
  }

  const url = `${baseUrl}/ModifyCustomerInformation/${idClient}`;
  return putData(url, data);
};
