import style from "./RowDetails.module.css";
// @ts-ignore
import { ModalAcceptCancel } from "@viuti/recursos";
import {
  formatHourDateToCustomFormat,
  formatToCustomFormat,
} from "@Utilities/formattedDate";
import { formatToSoles } from "@Utilities/FormatsHandler";

export const RowDetails = ({
  showModalDetailsHistory,
  setShowModalDetailsHistory,
}) => {
  if (showModalDetailsHistory?.documentId)
    return (
      <ModalAcceptCancel
        title={"Detalles"}
        showButtons={{
          showButtonClose: true,
          showButtonOne: false,
          showButtonTwo: false,
        }}
        actionButtonClose={() => setShowModalDetailsHistory(null)}
      >
        <div className={style.containerDetailsHistory}>
          <article>
            <p>N° de documento</p>
            <div>
              <p>{showModalDetailsHistory.documentNumber}</p>
            </div>
          </article>
          <article>
            <p>Fecha</p>
            <div>
              <p>{formatToCustomFormat(showModalDetailsHistory.dateTime)}</p>
            </div>
          </article>
          <article>
            <p>Hora</p>
            <div>
              <p>
                {formatHourDateToCustomFormat(showModalDetailsHistory.dateTime)}
              </p>
            </div>
          </article>
          <article>
            <p>Cajero</p>
            <div>
              <p>{showModalDetailsHistory.employeeName}</p>
            </div>
          </article>
          <article>
            <p>Valor</p>
            <div>
              <p>{formatToSoles(showModalDetailsHistory.value)}</p>
            </div>
          </article>
          <article>
            <p>Impuesto</p>
            <div>
              <p>{formatToSoles(showModalDetailsHistory.tax)}</p>
            </div>
          </article>
          <article>
            <p>ICBPER</p>
            <div>
              <p>{formatToSoles(showModalDetailsHistory.icbper)}</p>
            </div>
          </article>
          <article>
            <p>Descuento</p>
            <div>
              <p>{formatToSoles(showModalDetailsHistory.discount)}</p>
            </div>
          </article>
          <article>
            <p>Total</p>
            <div>
              <p>{formatToSoles(showModalDetailsHistory.total)}</p>
            </div>
          </article>
        </div>
      </ModalAcceptCancel>
    );
};
