import { Route, Routes } from "react-router-dom";
import { ROUTE_STORE } from "../models/routes/routes";
import MainView from "../pages/MainView/MainView";
import CustomerDetail from "@Pages/CustomerDetail/CustomerDetail";
// @ts-ignore
import { NotFoundPage } from "@viuti/recursos";

const MainRoutes = () => {
  return (
    <Routes>
      <Route path="*" element={<NotFoundPage />}></Route>
      <Route path={ROUTE_STORE.ROUTE_MAIN_VIEW} element={<MainView />} />
      <Route
        path={ROUTE_STORE.ROUTE_DETAIL_VIEW}
        element={<CustomerDetail />}
      />
    </Routes>
  );
};

export default MainRoutes;
