// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KmqGw_cHaC83YONQ5xQn {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  color: #717171;
  gap: 0.5rem;
  font: 800 16px "Mulish", sans-serif;
  white-space: nowrap;
  transition: all 150ms ease-in-out;
  z-index: 2;
  border: 1px solid #f5f5f5;
  &:hover {
    background-color: #ececec;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/TertiaryButton/TertiaryButton.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,2BAA2B;EAC3B,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;EAClB,yBAAyB;EACzB,eAAe;EACf,cAAc;EACd,WAAW;EACX,mCAAmC;EACnC,mBAAmB;EACnB,iCAAiC;EACjC,UAAU;EACV,yBAAyB;EACzB;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".button {\n  position: relative;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  padding: 8px 16px;\n  border-radius: 8px;\n  background-color: #f5f5f5;\n  cursor: pointer;\n  color: #717171;\n  gap: 0.5rem;\n  font: 800 16px \"Mulish\", sans-serif;\n  white-space: nowrap;\n  transition: all 150ms ease-in-out;\n  z-index: 2;\n  border: 1px solid #f5f5f5;\n  &:hover {\n    background-color: #ececec;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `KmqGw_cHaC83YONQ5xQn`
};
export default ___CSS_LOADER_EXPORT___;
