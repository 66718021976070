export default function formatNumberToPrice(number, applyFormat = true) {
  let numericValue = parseFloat(number);
  if (applyFormat) {
    numericValue = Number(numericValue);
    const formattedNumber = numericValue.toLocaleString("es-PE", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return formattedNumber;
  } else {
    return numericValue.toFixed(2);
  }
}
