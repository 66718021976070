import { capitalizeFirstLetters } from "@Utilities/capitalizeFirstLetters";
import { formatDocumentTable } from "@Utilities/formatDocument";

export const createObjectClientsMobile = ({ customer, screenWidth }) => {
  let headerTitles = [];
  if (screenWidth <= 900 && screenWidth > 370) {
    headerTitles = [
      capitalizeFirstLetters(customer.names),
      formatDocumentTable(customer.documentType, customer.documentNumber),
    ];
  }
  if (screenWidth <= 370) {
    headerTitles = [capitalizeFirstLetters(customer.names)];
  }

  return {
    id: customer.customerId,
    header: headerTitles,
  };
};
