import styles from "./MobileList.module.css";
// @ts-ignore
import { Icon, ListSkeleton } from "@viuti/recursos";

export const MobileList = ({ isLoading, icon, action, dataTable }) => {
  if (isLoading)
    return <ListSkeleton numberOfRows={10} gap={0} paddingRow={"12.5px"} />;
  return (
    <ul className={styles.container_list}>
      {dataTable.map((data) => (
        <li key={data.id} className={styles.container_row}>
          <div className={styles.row}>
            <div className={`${styles.titles}`}>
              {data.header.map((header, index) => (
                <span key={index + String(data.id)}>{header}</span>
              ))}
            </div>
            <button
              className={`${styles.iconTable}`}
              onClick={() => action(data.id)}
            >
              <Icon path={icon} size={15} alt={"flecha"} color={"#fff"} />
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
};
