import { createContext, useContext } from "react";

const ClientsContext = createContext({ setNotification: {} });

export const ClientsProvider = ({ setNotification, children }) => {
  return (
    <ClientsContext.Provider value={{ setNotification }}>
      {children}
    </ClientsContext.Provider>
  );
};

export const useClientsContext = () => useContext(ClientsContext);
