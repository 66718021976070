export const formatPhoneNumber = (phoneNumber: string | undefined): string => {
  if (!phoneNumber || phoneNumber.trim() === "") {
    return "";
  }

  // Eliminar todos los caracteres no numéricos
  const numericOnly = phoneNumber.replace(/\D/g, "");

  // Si la longitud es menor que 9, devolver la cadena numérica tal cual
  if (numericOnly.length <= 9) {
    return numericOnly;
  }

  // Tomar los últimos 9 dígitos
  const formattedNumber = numericOnly.slice(-9);

  return formattedNumber;
};
