import {
  ApiResponse,
  fetchData,
  promotionsBaseUrl,
} from "@Services/constServices";

export const getCategoryClient = async (): Promise<ApiResponse> => {
  const url = `${promotionsBaseUrl}/CategoryClient/GetCategoryClient`;
  return fetchData(url);
};
