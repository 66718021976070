export const formatDocumentTable = (type: number, number: string): string => {
  const option = Number(type);
  const documentType = formatDocumentType(option);
  if (documentType === "Falta el tipo" && number.trim() === "") {
    return "Falta el tipo y el número";
  }
  if (number.trim() === "") {
    return `${documentType} Falta el número`;
  }
  if (documentType === "Falta el tipo") {
    return `Falta el tipo ${number}`;
  }
  return `${documentType} ${number}`;
};

export const formatDocumentType = (type: number): string => {
  switch (type) {
    case 1:
      return "RUC";
    case 2:
      return "DNI";
    case 3:
      return "CARNET EXT.";
    case 4:
      return "PASAPORTE";
    default:
      return "Falta el tipo";
  }
};

export const formatDocument = (documentType, number: string): string => {
  const trimmedNumber = number.trim();

  if (trimmedNumber === "" && documentType === "") {
    return "Falta el tipo y el número";
  } else if (trimmedNumber === "") {
    return `${documentType} Falta el número`;
  } else if (documentType === undefined) {
    return `Falta el tipo ${trimmedNumber}`;
  }

  return `${documentType} ${trimmedNumber}`;
};
