import { adaptedCustomerSearch } from "@Adapters/adaptedCustomerSearch";
import { useCallback, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router";

const useDebouncedSearch = (initialValue, delay, setStateResponse) => {
  const [searchValue, setSearchValue] = useState(initialValue);
  const [listOfClientsFound, setListOfClientsFound] = useState([]);
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const navigate: NavigateFunction = useNavigate();
  const debounce = useCallback((func, delay) => {
    let timeoutId;
    return function (...args) {
      const context = this;
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func.apply(context, args), delay);
    };
  }, []);

  const delayedSearch = useCallback(
    debounce(async (value) => {
      setListOfClientsFound([]);
      // Here you can pass 'value' and any other necessary dependencies to your search function
      const res = await adaptedCustomerSearch(
        value,
        navigate,
        setStateResponse
      );
      setListOfClientsFound(res);
      setIsLoadingSearch(false);
    }, delay),
    [debounce, adaptedCustomerSearch, delay]
  );

  useEffect(() => {
    setIsLoadingSearch(true);
    delayedSearch(searchValue);
  }, [searchValue, delayedSearch]);

  const handleSearchChange = useCallback(
    (value) => {
      setSearchValue(value);
    },
    [setSearchValue]
  );

  return {
    searchValue,
    setSearchValue: handleSearchChange,
    listOfClientsFound,
    isLoadingSearch,
  };
};

export default useDebouncedSearch;
