import axios from "axios";
const GW_DOMAIN = "https://gw.zymple.io";
export const baseUrl = `${GW_DOMAIN}/customers/v1/Cliente`;
export const promotionsBaseUrl = `${GW_DOMAIN}/promotions/v1`;
export const tokenLocal = localStorage.getItem("access_token");

export const headerAuthorization = {
  headers: {
    Authorization: `Bearer ${tokenLocal}`,
  },
};
export interface ApiResponse {
  isSuccess: boolean;
  status: number;
  data: any;
  error: any;
}

export const handleSuccessfulResponse = (response: any): ApiResponse => ({
  isSuccess: true,
  status: response.status,
  data: response.data.data,
  error: null,
});

export const handleApiError = (error: any): ApiResponse => {
  const status = error?.response?.status || 500;
  return {
    isSuccess: false,
    status: status,
    data: null,
    error: error?.response?.data?.message || error?.message,
  };
};

export const fetchData = async (url: string): Promise<ApiResponse> => {
  try {
    const response = await axios.get(url, headerAuthorization);
    return handleSuccessfulResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const postData = async (
  url: string,
  data: any
): Promise<ApiResponse> => {
  try {
    const response = await axios.post(url, data, headerAuthorization);
    return handleSuccessfulResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};

export const putData = async (url: string, data: any): Promise<ApiResponse> => {
  try {
    const response = await axios.put(url, data, headerAuthorization);
    return handleSuccessfulResponse(response);
  } catch (error) {
    return handleApiError(error);
  }
};
