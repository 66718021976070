import { getCustomerReservations } from "@Services/clients/getCustomerReservations";
import {
  handleApiError,
  handleSuccessfulResponse,
} from "@Services/constServices";
import { formatDayMonthYear } from "@Utilities/formatDayMonthYear";
import { formatTime } from "./formatTime";

export const adaptCustomerReservations = async (idClient: string) => {
  const response = await getCustomerReservations(idClient);

  if (response.isSuccess) {
    const sortedReservations = response.data.reservations.sort((a, b) => {
      const dateA: Date = new Date(
        `${a.serviceReservations[0].reservationDate} ${a.serviceReservations[0].reservationTime}`
      );
      const dateB: Date = new Date(
        `${b.serviceReservations[0].reservationDate} ${b.serviceReservations[0].reservationTime}`
      );
      return dateA.getTime() - dateB.getTime();
    });

    const reservations = {
      totalReservations: response.data.totalReservations,
      percentageRescheduled: response.data.percentageRescheduled,
      percentageUnattended: response.data.percentageUnattended,
    };

    const dataTable = sortedReservations.map((reservation) => ({
      columnOne: formatDayMonthYear(
        reservation.serviceReservations[0].reservationDate
      ),
      columnTwo: formatTime(reservation.serviceReservations[0].reservationTime),
      columnThree: "Ver detalles",
    }));

    return {
      ...handleSuccessfulResponse(response),
      dataTable: dataTable,
      reservations: reservations,
    };
  } else {
    return {
      ...handleApiError(response),
      dataTable: [],
      reservations: {},
    };
  }
};
